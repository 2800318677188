<template>
    <div class="rb-view" style="background:initial;margin:initial">
        <div class="rb-info-card">
            <div class="rb-info-card-title-view">
                <span style="display: inline-block;border-left: 4px solid #155bd4;padding: 0 10px;margin: 5px 0;font-size: 14px;color: #323233;">基本信息</span>
            </div>
            <div>
                <div class="rb-info-card-body-view">
                    <div style="display:flex" >
<!--                        <el-col style="flex:1;">-->
                        <div>
                            <div style="margin:0 30px auto 10px">
                                <el-avatar :src="user_detail.avatar" :size="60"></el-avatar>
                            </div>
                        </div>

                        <div style="flex:1">
                            <el-row>
                                <el-col>
                                    <div>
                                        <span style="font-size:16px;font-weight: 700">{{user_detail.nick_name}}</span>
                                    </div>
                                    <div>

                                    </div>
                                </el-col>
                            </el-row>

                            <div>
                                <el-row>
                                    <el-col :span="8"><span class="rb-item-label">ID:</span><span class="rb-item-val">{{user_detail.id}}</span></el-col>
                                    <!--                  <el-col :span="8"><span class="rb-item-label">性别:</span><span class="rb-item-val">{{user_detail.gender}}</span></el-col>-->
                                    <el-col :span="8"><span class="rb-item-label">姓名:</span><span class="rb-item-val">{{user_detail.real_name}}</span></el-col>
                                    <el-col :span="8"><span class="rb-item-label">手机号:</span><span class="rb-item-val">{{user_detail.telephone}}</span></el-col>
<!--                                    <el-col :span="8"><span class="rb-item-label">地区:</span><span class="rb-item-val">{{user_detail.country}} {{user_detail.province}} {{user_detail.city}}</span></el-col>-->

                                    <el-col :span="8"><span class="rb-item-label">首次访问:</span><span class="rb-item-val">{{timeFormatter(user_detail.created_at)}}</span></el-col>
                                    <el-col :span="8"><span class="rb-item-label">最近登录:</span><span class="rb-item-val">{{timeFormatter(user_detail.last_login_at)}}</span></el-col>
                                    <el-col :span="8"><span class="rb-item-label">登录次数:</span><span class="rb-item-val">{{user_detail.logins}}</span></el-col>
<!--                                    <el-col :span="24"><span class="rb-item-label">上级代理:</span><span class="rb-item-val"></span></el-col>-->

<!--                                    <el-col :span="8"><span class="rb-item-label">最近收货地址:</span><span class="rb-item-val"></span></el-col>-->

<!--                                    <el-col :span="24"><span class="rb-item-label">备注:</span><span class="rb-item-val"></span></el-col>-->
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!--   微信信息 BEGIN   -->
        <div class="rb-info-card">
            <div class="rb-info-card-title-view">
                <span style="display: inline-block;border-left: 4px solid #155bd4;padding: 0 10px;margin: 5px 0;font-size: 14px;color: #323233;">微信资料</span>
            </div>
            <div class="rb-info-card-body-view">
                <el-row>
                    <el-col :span="6"><span class="rb-item-label">昵称:</span><span class="rb-item-val">{{user_detail.nick_name}}</span></el-col>
                    <el-col :span="6"><span class="rb-item-label">地区:</span><span class="rb-item-val">{{user_detail.country}} {{user_detail.province}} {{user_detail.city}}</span></el-col>
                    <el-col :span="6"><span class="rb-item-label">性别:</span><span class="rb-item-val">{{user_detail.gender}}</span></el-col>
                    <el-col :span="6"><span class="rb-item-label">语言:</span><span class="rb-item-val">{{user_detail.language}}</span></el-col>
                    <el-col :span="12"><span class="rb-item-label">小程序 - OpenId:</span><span class="rb-item-val">{{user_detail.open_id}}</span></el-col>
<!--                    <el-col :span="12"><span class="rb-item-label">公众号 - OpenId:</span><span class="rb-item-val">{{ user_detail.wx_offi_account_open_id}}</span></el-col>-->
                    <el-col :span="12"><span class="rb-item-label">开放平台 - UnionId:</span><span class="rb-item-val">{{user_detail.union_id}}</span></el-col>
                </el-row>
            </div>
        </div>
        <!--   微信信息 END   -->


        <!--   资产信息 BEGIN   -->
        <div class="rb-info-card">
            <div class="rb-info-card-title-view">
                <span style="display: inline-block;border-left: 4px solid #155bd4;padding: 0 10px;margin: 5px 0;font-size: 14px;color: #323233;">资产信息</span>
            </div>
            <div class="rb-info-card-body-view">
                <el-row>
                    <el-col :span="4"><div class="user-col-label">累计优惠券</div><div class="user-col-val">{{user_detail.coupon_record_count}}</div></el-col>
                    <el-col :span="4"><div class="user-col-label">可用优惠券</div><div class="user-col-val">{{user_detail.coupon_valid_count}}</div></el-col>
                    <el-col :span="4"><div class="user-col-label">过期优惠券</div><div class="user-col-val">{{user_detail.coupon_expire_count}}</div></el-col>

<!--                    <el-col :span="4"><div class="user-col-label">绑定银行卡</div><div class="user-col-val">{{user_detail.bank_card_count}}</div></el-col>-->
<!--                    <el-col :span="4"><div class="user-col-label">收货地址</div><div class="user-col-val">{{user_detail.address_count}}</div></el-col>-->
<!--                    <el-col :span="4"><div class="user-col-label">他的收藏</div><div class="user-col-val">{{user_detail.product_collection_count}}</div></el-col>-->
<!--                    <el-col :span="4"><div class="user-col-label">购物车商品</div><div class="user-col-val">{{user_detail.shopping_cart_count}}</div></el-col>-->
                </el-row>
            </div>
        </div>
        <!--   资产信息 END   -->
    </div>
</template>

<script>
    import{getDetail} from "@/api/hado/user";
    import {formatTimestamp} from "@/utils/time";

    export default {
        name: "user-detail",
        data(){
            return{
                user_detail:{}
            }
        },
        created(){
            this.user_detail.id = this.$route.query.id
            this.initPage()
        },
        methods:{
            initPage(){
                this.getUserDetail(this.user_detail.id)
            },
            reloadPage(){
                this.initPage()
            },
            getUserDetail(user_id){
                getDetail(user_id).then(resp=>{
                    this.user_detail = resp.data
                })
            },
            timeFormatter(ts){
                return formatTimestamp(ts)
            }
        }
    }
</script>

<style scoped>
    .rb-info-card{
        padding:15px;
        background-color:#FFFFFF;
        margin-bottom:20px;
    }
    .rb-info-card:last-child{margin-bottom:0;}
    .rb-info-card-title-view{

    }
    .rb-info-card-body-view{
        padding-top:15px;
    }
    /deep/.rb-info-card-body-view .el-col{
        margin-bottom:10px;
    }

    .rb-item-label,.rb-item-val{
        font-size: 14px;
        line-height: 20px;
        color: #323233;
        margin-bottom: 10px;
    }
    .rb-item-label{
        font-weight: 600;
    }
    .rb-item-val{
        padding-left:8px
    }
    .user-col-label{
        font-size: 14px;
        line-height: 20px;
        color: #323233;
    }
    .user-col-val{
        display: block;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #323233;
        line-height: 24px;
    }
</style>
